import(/* webpackMode: "eager" */ "/app/src/app/(site)/[lang]/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalFooter"] */ "/app/src/components/global-footer/GlobalFooter.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalHeader"] */ "/app/src/components/global-header/GlobalHeader.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteBanners"] */ "/app/src/features/site-banners/components/site-banners/SiteBanners.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UpdateOnetrustLanguage"] */ "/app/src/features/site-language/components/update-onetrust-language/UpdateOnetrustLanguage.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageStoreProvider"] */ "/app/src/features/site-language/store/language-store-provider.tsx");
