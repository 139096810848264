'use client';
import { useEffect } from 'react';

// This component is used to update the language of the OneTrust cookie banner.
// https://developer.onetrust.com/onetrust/docs/javascript-api#change-language
export const UpdateOnetrustLanguage = ({ lang }: { lang: string }) => {
    const language = lang.split('-')[0].toLocaleLowerCase();

    useEffect(() => {
        const checkOneTrust = () => {
            if (document.documentElement.lang.toLowerCase() !== lang.toLowerCase()) {
                if (window.OneTrust && typeof window.OneTrust.changeLanguage === 'function') {
                    window.OneTrust.changeLanguage(language);
                } else {
                    setTimeout(checkOneTrust, 100); // Check again after 100ms
                }
            }
        };

        checkOneTrust();
    }, [language, lang]);

    return <></>;
};
